<template>
  <div>
    <PageHeader>
      <template slot="right">
        <div>
          <b-button
            v-if="$allowPermission('agent:manage.user-agent')"
            to="/assistants/create"
            variant="primary"
          >
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>
      </template>
    </PageHeader>
    <div class="card">
      <div class="card-body">
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row
          align-v="center"
          class="mb-3"
        >
          <b-col
            cols="12"
            md="3"
          >
            <LimitSelect v-model="selectedLimit"/>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <b-row>
              <b-col cols="5">
                <b-input-group>
                  <b-form-input
                    v-model="search"
                    :placeholder="`${$t('fields.search')}... `"
                    class="mx-1"
                    type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col>
                <MasterSelectInput
                  :value="selectedMaster"
                  hide-label
                  @update="onMasterIdChange"
                />
              </b-col>
              <b-col>
                <AgentsSelectInput
                  :master-id="selectedMaster"
                  :value="selectedAgent"
                  hide-label
                  @update="onAgentIdChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table
          :busy="isFetching"
          :fields="fields"
          :items="assistantList"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(agent)="data">
            {{ data.value.name }}
          </template>
          <template #cell(creator)="data">
            <span v-if="data.value">
              {{ data.value.name }}
              ({{ data.value.username }})
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(isBanned)="data">
            <b-badge :variant="data.value ? 'danger' : 'success'">{{data.value ? 'ระงับ' : 'ปกติ'}}</b-badge>
          </template>
          <template #cell(id)="data">
            <b-dropdown
              v-if="$allowPermission('agent:manage.user-agent')"
              dropleft
              size="sm"
              variant="info"
            >
              <template #button-content>
                <i class="uil uil-cog"></i>
              </template>
              <b-dropdown-item :to="`/assistants/detail/${data.item.id}`">
                <i class="uil uil-edit-alt mr-2"></i>
                {{ $t('buttons.edit')}}
              </b-dropdown-item>
              <b-dropdown-item @click="toggleResetPasswordModal(data.item)">
                <i class="uil uil-unlock-alt mr-2"></i>
                {{ $t('buttons.reset_password') }}

              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item
                v-if="!!data.item.pin"
                @click="onRemovePin(data.item.id)"
              >
                <i class="uil uil-times-circle mr-2"></i>
                {{ $t('buttons.remove_pin') }}
              </b-dropdown-item>
              <b-dropdown-item @click="onBan(data.value)">
                <span :class="data.item.isBanned ? 'text-success' : 'text-danger'">
                  <i class="uil uil-times-circle mr-2"></i>
                  {{ $t(data.item.isBanned ? 'buttons.un_ban' :'buttons.ban' ) }}
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="onLogin(data.value)">
                <i class="uil uil-sign-out-alt mr-2"></i>
                {{ $t('buttons.access_login') }}
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                @click="onDelete(data.value)"
              >
                <i class="uil uil-ban mr-2"></i>
                <span>
                  {{ $t('buttons.remove')}}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
          </template>
        </b-table>
        <!-- pagination -->
        <PaginationInput
          :per-page="limit"
          :total="total"
          @update="(val) => currentPage = val"
        />
      </div>
    </div>
    <b-modal
      v-model="isCreditTransferModalActive"
      :title="`${$t('credits.direct_transfer')} ( ${$t('routes.VIEW_ASSISTANTS')}-${selectedAssistant.username|| ''})`"
      hide-footer
    >
      <DirectCreditTransferForm @close="onCloseModal" />
    </b-modal>
    <ResetPasswordFormModal
      ref="assistantResetPassword"
      :loading="isUpdatingAssitant"
      no-btn
      @reset-password="onSubmitResetPassword"
    />
  </div>
</template>

// TODO no pagination
<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายการผู้ช่วย',
  },
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
    DirectCreditTransferForm: () =>
      import('@components/forms/direct-credit-transfer-form'),
    ResetPasswordFormModal: () =>
      import('@components/forms/reset-password-form-modal'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      isCreditTransferModalActive: false,
      selectedAssistant: {},
      selectedAgent: '',
      selectedMaster: '',
      onSearchTimeout: null,
      search: '',
      fields: [
        '#',
        { key: 'agent', label: 'เอเย่นต์' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'name', label: 'ชื่อ' },
        { key: 'phoneNumber', label: this.$t('user.phone') },
        { key: 'creator', label: 'คนสร้าง' },
        { key: 'createdAt', label: 'สร้างเมื่อ' },
        { key: 'isBanned', label: 'สถานะ' },
        {
          key: 'id',
          label: '',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) =>
        state.assistant.isFetchingAssistants ||
        state.assistant.isDeletingAssistant,
      isUpdatingAssitant: (state) => state.assistant.isUpdatingAssistant,
      isUpdatingAssistantSuccess: (state) =>
        state.assistant.isUpdatingAssistantSuccess,
    }),
    ...mapGetters(['assistants']),
    assistantList() {
      const { items } = this.assistants
      return items || []
    },
    pagination() {
      return this.assistants.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    search() {
      this.delaySearch()
    },
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },

  methods: {
    ...mapActions([
      'fetchAssistants',
      'updateAssistant',
      'deleteAssistant',
      'banAssistant',
      'loginAs',
      'removePin',
    ]),
    onLogin(id) {
      this.loginAs(id)
    },
    fetchData() {
      this.fetchAssistants({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    toggleModal(user) {
      this.isCreditTransferModalActive = !this.isCreditTransferModalActive
      this.selectedAssistant = user
    },
    onCloseModal() {
      this.isCreditTransferModalActive = false
      this.selectedAssistant = {}
    },
    toggleResetPasswordModal(user) {
      this.$refs.assistantResetPassword.toggleModal()
      this.selectedAssistant = user
    },
    onCloseResetPasswordModal() {
      this.$refs.assistantResetPassword.onClose()
      this.selectedAssistant = {}
    },
    async onSubmitResetPassword({ password }) {
      await this.updateAssistant({
        id: this.selectedAssistant.id,
        data: {
          name: this.selectedAssistant.name,
          phoneNumber: this.selectedAssistant.phoneNumber,
          password,
        },
      })
      if (this.isUpdatingAssistantSuccess) {
        this.onCloseResetPasswordModal()
      }
    },
    onDelete(assistantId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((value) => {
          if (value) {
            this.deleteAssistant(assistantId)
          }
        })
    },
    onBan(agentId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.banAssistant(agentId)
          }
        })
    },
    onRemovePin(userId) {
      this.removePin(userId)
    },
  },
}
</script>